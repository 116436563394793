import {Card, Col, Form, Row} from "reactstrap";
import Dropzone from "react-dropzone";
import {Link} from "react-router-dom";
import React, { useEffect, useState } from "react";
import {TranslateContext} from "../../providers/Translate";
import {NotifyContext} from "../../providers/Notify"
import { formatBytes } from "../../helpers/helpers_functions";
import { useDispatch, useSelector } from "react-redux";
import { useSpring, animated } from '@react-spring/web'

import { useLinks } from "../../Hooks/LinksHooks"

import {
  showRightSidebarAction,
  showSearchBarAction,
  showUploaderBarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

export const TeleLinkUploadBlock = ({
  maxFileSize,
  isFilesUploading,
  isFilesConverting,
  sendFiles,
  terminationDate
}) => {

  const dispatch = useDispatch();
  const {language} = React.useContext(TranslateContext)
  const { setNotify } = React.useContext(NotifyContext)

  const [TeleLink, SetTeleLink] = useState("")
  const [TeleLinkName, SetTeleLinkName] = useState("")
  const [TeleLinkDateTime, SetTeleLinkDateTime] = useState("")
  const [TeleLinkSingle, SetTeleLinkSingle] = useState(false)

  const { error, AddLink } = useLinks({onDone: (operation, data)=>{ 

  }})

  const styles = useSpring({
    from: {
      opacity: 0,
      y: '10%',
    },
    to: {
      opacity: 1,
      y: '0%',
    },
  })

  const AddNewLink = () => {
    AddLink({ variables: {
      name: TeleLinkName, 
      link: TeleLink, 
      linkDateTime: TeleLinkDateTime != "" ? TeleLinkDateTime : null
    }})
  }

  useEffect(()=>{
    if (error!="")
    {
      setNotify({ message: error, type: 'warning' })
    }
  }, [error])

  return (
    <animated.div  style={{...styles, marginBottom:20}}>
    <div className="card" style={{minHeight:340, height:"100%"}}>
        <div className="card-body h-100">
              <div className="d-flex flex-column justify-content-between" style={{height:"100%"}}>
                    <h3 className="text-center">{"Вставьте ссылку на телемост и укажите название."}</h3>
                    <input type="text" value={TeleLink} className="form-control m-0 pe-5 d-block w-100" onChange={(e)=>SetTeleLink(e.target.value)} placeholder="Ссылка" />
                    <input type="text" value={TeleLinkName} className="form-control m-0 pe-5 d-block w-100" onChange={(e)=>SetTeleLinkName(e.target.value)} placeholder="Название" />

                    <div className="form-check form-switch form-switch-lg" dir="ltr">
                          <input className="form-check-input" type="checkbox" id="BetaModelChecked" checked={TeleLinkSingle} onClick={()=>
                            {
                              SetTeleLinkSingle(!TeleLinkSingle)
                            }} />
                          <label className="form-check-label" htmlFor="BetaModelChecked">{"Многоразовая запись по ссылке"}</label>
                    </div>

                    {TeleLinkSingle && <input type="datetime-local" value={TeleLinkDateTime} className="form-control m-0 pe-5 d-block w-100" onChange={(e)=>SetTeleLinkDateTime(e.target.value)} placeholder="Время и дата (ЧЧ.мм ДД.ММ.ГГ)" />}

                    <button type="button" className="btn btn-primary"
                        onClick={ () => {
                          AddNewLink()
                        }}>
                        {"Записать"}
                    </button>
              </div>
        </div>
    </div>
    </animated.div>
  )
}
