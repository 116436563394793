import { useState } from "react";

import { useLazyQuery, useMutation } from '@apollo/client';

import graphql from "../graphql/gql"

export const useLinks = ({onDone}) => {
  const [error, SetError] = useState("");
  const [links, SetLinks] = useState();

  const onError = ( error ) => {
    SetError(error.message)
    console.log(error)
    setTimeout(() => {
      SetError("")
    }, 2000)
  }
  
  const [AddLink] = useMutation(graphql.mutations.ADDLINK,
    {
      onCompleted: (data) => {
         if (onDone) onDone("newlink", data.addLink)
      },
      onError(error) {
          SetError(error.message)
          setTimeout(() => {
            SetError("")
          }, 2000)
      },
  });   

  const [RemoveLink] = useMutation(graphql.mutations.REMOVELINK,
    {
      onCompleted: (data) => {
         if (onDone) onDone("removelink", data.removeLink)
      },
      onError(error) {
          SetError(error.message)
          setTimeout(() => {
            SetError("")
          }, 2000)
      },
  });   

  const [UpdateLink] = useMutation(graphql.mutations.UPDATELINK,
    {
      onCompleted: (data) => {
         if (onDone) onDone("updatelink", data.updateLink)
      },
      onError(error) {
          SetError(error.message)
          setTimeout(() => {
            SetError("")
          }, 2000)
      },
  });   

  const [LoadLinks] = useLazyQuery(graphql.queries.LINKS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => SetLinks(data.links),
    onError,
  })

  return { error, links, LoadLinks, AddLink, RemoveLink, UpdateLink }
}
