import { Card, Col, Form, Row } from 'reactstrap'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import { Button, CardBody } from 'reactstrap'
import React, { useEffect, useRef, useState } from 'react'
import { TranslateContext } from '../../providers/Translate'
import { formatBytes } from '../../helpers/helpers_functions'
import { useDispatch, useSelector } from 'react-redux'
import { useProfile, useUsers } from '../../Hooks/UserHooks'
import chroma from 'chroma-js';
import { useSpring, animated } from '@react-spring/web'

import {
    showRightSidebarAction,
    showSearchBarAction,
    showUploaderBarAction,
    toggleLeftmenu,
    changeSidebarType,
} from '../../store/actions'

let updateState = false
let panelVisisble = false


export const SearchBlock = ({ searchlist, onChange, onEnter, global, refresh, onTagsChange }) => {
    const dispatch = useDispatch()

    const { language } = React.useContext(TranslateContext)
    const [searchText, setSearchText] = useState('')
    const [showhints, setShowHints] = useState(false)
    const [showtags, SetShowTags] = useState(false)
    const inputElement = useRef(null)
    const hintsPanel = useRef(null)
    const [tags, setUsersTags] = React.useState([])
    const [selectedTags, setSelectedTags] = useState(JSON.parse(localStorage.getItem('selectedTags') ?? '[]'))

    const styles = useSpring({
        from: {
          opacity: 0,
          y: '10%',
        },
        to: {
          opacity: 1,
          y: '0%',
        },
        config: { duration: 500 },
      })
    

    const { error, UserTags, AddTag, RemoveTag, UpdateTag, AssignTagToOrder, RemoveTagFromOrder, UpdateOrderTags } = useUsers({
        onDone: (type, data) => {
           if (type == 'tags') {
    
            let d = data?.map((d) => {
                return {
                  label: d.name, value:d.id, color:d.color, global: false, parent: d.parent, id: d.id, ordersCount: d.ordersCount
                }
            })

            d.sort((a, b) => b.ordersCount - a.ordersCount)
    
            setUsersTags(d)
                
           }
        }
    })

    function mouseDownHandler(e) {
        if (panelVisisble) {
            e.preventDefault()
            inputElement.current.blur()
            setShowHints(false)
        }
    }

    function keyDownHandler(e) {
        if (e.key === 'Escape') {
            e.preventDefault()
            inputElement.current.blur()
            setShowHints(false)
        }

        if (e.key === 'Enter') {
            e.preventDefault()
            if (onEnter) onEnter(inputElement.current.value)
            inputElement.current.blur()
            setShowHints(false)
            updateState = false
        }
    }

    let RealignPanel = () => {
        if (showhints) {
            let rect = inputElement.current.getBoundingClientRect()
            hintsPanel.current.style.left = 42 + 'px'
            hintsPanel.current.style.top = 59 + 'px'
            hintsPanel.current.style.width =
                inputElement.current.clientWidth - 16 + 'px'
            hintsPanel.current.style.display = 'block'
        } else {
            hintsPanel.current.style.display = 'none'
        }
    }

    useEffect(() => {
        if (onChange) onChange(searchText)
        if (updateState && onEnter) onEnter(searchText)
        updateState = false
    }, [searchText])

    useEffect(() => {
        RealignPanel()
        panelVisisble = showhints
    }, [showhints, hintsPanel])

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler)
        document.addEventListener('mousedown', mouseDownHandler)

        return () => {
            document.removeEventListener('keydown', keyDownHandler)
            document.removeEventListener('mousedown', mouseDownHandler)
        }
    }, [])

    useEffect(() => {
        if (showtags)
        {
            UserTags({variables: {withGlobal: true}})
        }
    }, [showtags, refresh])

    useEffect(()=>{
        
        if (selectedTags.length>0)
        {
            SetShowTags(true)   
        }
    }, [selectedTags])

    return (
        <>
            <div className="card">
                <div className="card-body p-0 pt-3 px-3">
                    <div className="d-flex w-100 position-relative">
                        <div className="d-flex w-100 position-relative">
                            <input
                                ref={inputElement}
                                className="form-control w-100"
                                type="text"
                                style={{ marginRight: 10 }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setShowHints(true)
                                }}
                                onFocus={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setShowHints(true)
                                }}
                                onBlur={() => {}}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            {searchText != '' ? (
                                <Button
                                    className="btn btn-sm border-0 position-absolute"
                                    size="sm"
                                    outline
                                    style={{
                                        marginLeft: 15,
                                        marginRight: 0,
                                        zIndex: 100,
                                        top: 5,
                                        right: 15,
                                    }}
                                    onClick={() => {
                                        updateState = true
                                        setSearchText('')
                                    }}
                                >
                                    <i className="fas fa-times text-muted" />
                                </Button>
                            ) : null}
                        </div>


                        {document.location.hostname != "i.timelist.ru" && <button
                            type="button"
                            className={showtags ? "btn btn-primary waves-effect waves-light me-2" : "btn btn-outline-secondary waves-effect waves-light me-2"}
                            onClick={() => {
                                SetShowTags(!showtags)
                            }}
                        >
                            теги
                        </button>}

                        <button
                            type="button"
                            className="btn btn-outline-primary waves-effect waves-light me-2"
                            onClick={() => {
                                if (onEnter) onEnter(inputElement.current.value)
                                inputElement.current.blur()
                                setShowHints(false)
                                updateState = false
                            }}
                        >
                            Найти
                        </button>

                        <button
                            type="button"
                            className="btn waves-effect waves-light"
                            onClick={() => {
                                dispatch(showSearchBarAction(false))
                            }}
                        >
                            X
                        </button>
                    </div>

                    <div className="d-flex w-100 position-relative justify-content-end pt-3">
                        {global ? (
                            <Button
                                className="btn"
                                size=""
                                outline
                                style={{ zIndex: 100 }}
                                onClick={() => {}}
                            >
                                <i
                                    className="fas fa-search text-muted"
                                    style={{ marginRight: 10 }}
                                />
                                Искать по всем
                            </Button>
                        ) : null}
                    </div>
                


                <div
                className="search-shadow position-absolute"
                style={{
                    zIndex: 500,
                    height: 500,
                    background: 'white',
                    borderBottom: '1px solid lightgray',
                    borderLeft: '1px solid lightgray',
                    borderRight: '1px solid lightgray',
                    borderBottomLeftRadius: '20px',
                    borderBottomRightRadius: '20px',
                    overflowY: 'scroll',
                    overflowX: 'clip',
                }}
                ref={hintsPanel}
            >
                {searchlist?.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className="d-flex justify-content-between mouse-hover"
                            style={{
                                padding: 8,
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                            onMouseDown={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                updateState = true
                                setSearchText(item?.search ?? '')
                                setShowHints(false)
                            }}
                        >
                            <span
                                style={{ fontSize: 20, fontWeight: 'semibold' }}
                            >
                                {item?.search ?? '---'}
                            </span>
                            <span style={{ fontSize: 18 }}>
                                {item?.complitedCounts ?? 0}
                            </span>
                        </div>
                    )
                })}
            </div>

            <animated.div  style={{...styles}}>
            <div className="d-flex flex-wrap w-100 mb-3">
            {showtags && tags?.length>0 && tags?.map((item, index) => 
                <span
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()

                            if (selectedTags.includes(item.id)) {
                                let stags = selectedTags.filter((i) =>  i && i != item.id)
                                setSelectedTags(stags)
                                localStorage.setItem('selectedTags', JSON.stringify(stags))   
                                if (onTagsChange) onTagsChange(stags)
                            } else 
                            {
                                let stags = [...selectedTags, item.id]
                                setSelectedTags(stags)
                                localStorage.setItem('selectedTags', JSON.stringify(stags))
                                if (onTagsChange) onTagsChange(stags)
                            }
                        }}
                        key={index}
                        style={{
                            padding: 5,
                            marginRight:10,
                            marginBottom:10,
                            width:"auto",
                            backgroundColor: chroma(item.color).alpha(selectedTags.includes(item.id) ? 0.8 : 0.3).css(),
                            color:"black",
                            borderRadius: 10,
                            border: selectedTags.includes(item.id) ? "3px solid #49423D" : "3px solid transparent",
                            cursor:"pointer",
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            fontSize:"10.5px"
                        }}
                    >
                    <span style={{fontWeight: selectedTags.includes(item.id) ? "bold" : "normal"}}>{item.label}</span> 
                    <span style={{marginLeft:10, fontWeight:selectedTags.includes(item.id) ? "bold" : "normal", fontSize:"10.5px"}}>{item.ordersCount}</span>
                </span>
            )}
           </div>
           </animated.div>

            </div>
        </div>
           
        </>
    )
}
