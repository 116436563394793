import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Container, DropdownItem, DropdownMenu, Modal, DropdownToggle, Nav, Form, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown, CardBody, CardHeader } from "reactstrap";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import Dropzone from "react-dropzone";

import classnames from "classnames";
import MetaTags from "react-meta-tags";
import Moment from 'react-moment';

import { Saturation, Hue, useColor } from "react-color-palette";
import "react-color-palette/css";

//SimpleBar
import SimpleBar from "simplebar-react";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import chroma from 'chroma-js';

import { Link } from 'react-router-dom';

import { appContext } from "../../App";

import {useFileUploader} from '../../Hooks/FileUploaderHook'
import { useProfile, useUsers } from "../../Hooks/UserHooks";
import {usePresets} from "../../Hooks/PresetsHooks"
import { useLinks } from '../../Hooks/LinksHooks';

import { TranslateContext } from "../../providers/Translate";
import { NotifyContext } from "../../providers/Notify";

import { useSpring, animated } from '@react-spring/web'

var delfile = null
var dontsave = true
let tagsList = []
let edittagId = ""
let tagEditorValue = ""
const Links = () => {
  const { userId } = useProfile();
  const dispatch = useDispatch();
  const { language } = React.useContext(TranslateContext)
  const { error: presetserror, LoadPresets } = usePresets()
  const { setNotify } = React.useContext(NotifyContext);

  const tagInput = React.createRef()
  const [activeTag, setActiveTag] = React.useState(null)
  const [showPalette, setShowPalette] = useState(false);
  const [selectedColor, setColor] = useColor("#561ecb");
  const [terms, SetTerms] = React.useState(null)
  const [term, SetNewTerm] = React.useState("")
  const [edittag, SetEditTag] = React.useState(null)
  const [showpage, SetShowPage] = React.useState(true)

  const { error, links, LoadLinks, RemoveLink, UpdateLink } = useLinks({onDone: (operation, data)=>{ 
      if (operation == "removelink")
      {
          LoadLinks({variables: {
              multirecorded: showpage
          }})
      }
  }})
  
  const styles = useSpring({
    from: {
      opacity: 0,
      y: '10%',
    },
    to: {
      opacity: 1,
      y: '0%',
    },
    config: { duration: 500 },
  })

  const DeleteLink = (linkId) => {
     RemoveLink({variables: {
        id: linkId
     }})
  }

  useEffect(()=>{
      SetShowPage(true)
  }, [])

  useEffect(()=>{
    if (dispatch)
    {
        const breadCrumbItems = {
            title: showpage ? "Повторяющиеся встречи" : "Разовые встречи",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }
  }, [dispatch, showpage])

  useEffect(()=>{
   
    LoadLinks({variables: {
        multirecorded: showpage
    }})
   
  }, [showpage])
    
    return (
        <React.Fragment>
        <MetaTags>
            <title>Кабинет | Таймлист - ИИ расшифровки аудио и автопротоколирования</title>
        </MetaTags>
        <div className="page-content">
            <Container fluid>

                <Row style={{marginBottom: "20px"}}>
                    <Col md={12} lg={12} xl={12}>
                        <button type="button" style={{marginRight: "10px"}} className={showpage ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => SetShowPage(true)}>{"Повторяющиеся встречи"}</button>
                        <button type="button" className={!showpage ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => SetShowPage(false)}>{"Разовые встречи"}</button>
                    </Col>
                </Row> 

                {showpage &&
                <Row>
                    <Col md={12} lg={12} xl={12}>
                    <animated.div  style={{...styles}}>
                        <Card className="mb-0">
                                        <CardBody>
                                            <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                                <div className="w-100 row">
                                                        {links?.map(((item)=>{
                                                            let code = item.link.split("/")
                                                            return <div className='card col-3' style={{width:250, marginRight:20, padding:10}}>
                                                                <button type="button" style={{position:"absolute", right:-5, top:-5}} className='btn btn-sm btn-outline-primary' onClick={() => {
                                                                    DeleteLink(item.id)
                                                                } }>{"X"}</button>
                                                                <div className='text-center' style={{fontSize:14, fontWeight:"bold"}}>{item.name}</div>
                                                                <div className='text-center' style={{fontSize:14, fontWeight:"normal", marginBottom:15}}><Moment format='DD.MM.YYYY HH:mm'>{item.linkDateTime}</Moment></div>
                                                                <div className='text-center' style={{fontSize:14, fontWeight:"normal"}}>{code[code.length-1]}</div>
                                                            </div>
                                                        }))}
                                                </div>
                                            </div>
                                        </CardBody>
                        </Card>
                        </animated.div>
    
                    </Col>
                </Row> }


                {!showpage &&
                <Row>
                    <Col md={12} lg={12} xl={12}>
                    <animated.div  style={{...styles}}>
                        <Card className="mb-0">
                                        <CardBody>
                                            <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                                <div className="w-100 row">
                                                            {links?.map(((item)=>{
                                                                let code = item.link.split("/")
                                                                return <div className='card col-3' style={{width:250, marginRight:20, padding:10}}>
                                                                        <button type="button" className='btn btn-sm btn-outline-primary' style={{position:"absolute", right:-5, top:-5}} onClick={() => {
                                                                            DeleteLink(item.id)
                                                                        } }>{"X"}</button>
                                                                        <div className='text-center' style={{fontSize:16, fontWeight:"bold", marginBottom:15}}>{item.name}</div>
                                                                        <div className='text-center' style={{fontSize:14, fontWeight:"normal"}}>{code[code.length-1]}</div>
                                                                </div>
                                                            }))}
                                                </div>
                                            </div>
                                        </CardBody>
                        </Card>
                        </animated.div>
    
                    </Col>
                </Row>}
            </Container>
        </div>
    </React.Fragment>
    );
};


export default Links;

