import React from "react";
import { Redirect } from "react-router-dom";

// Contacts
import Profile from "../pages/Contacts/Profile";
import Jurface from "../pages/Jurface/Jurface"
import {MyFiles} from "../pages/MyFiles/MyFiles"
import Terms from "../pages/Terms/Terms"
import Links from "../pages/Links/Links"
import Wallet from "../pages/Wallet/Wallet"
import Feedback from "../pages/Feedback/Feedback"
import FaqsPage from "../pages/Faq/pages-faqs";
import UserGrid from "../pages/Contacts/UserGrid";

// Auth
import AuthLogin from "../pages/Auth/login";
import Register from "../pages/Auth/Register";
import RecoverPassword from "../pages/Auth/RecoverPassword";
import LockScreen from "../pages/Auth/LockScreen";
import Logout from "../pages/Auth/Logout";
import ConfirmMail from "../pages/Auth/ConfirmMail";
import EmailVerification from "../pages/Auth/EmailVerification";
import TwoStepVerification from "../pages/Auth/TwoStepVerification";

// Utility
import MaintenancePage from "../pages/Utility/pages-maintenance";
import ComingSoonPage from "../pages/Utility/pages-comingsoon";
import Error404 from "../pages/Utility/pages-404";
import Error500 from "../pages/Utility/pages-500";

//Authentication
import Login from "../pages/Authentication/login";

const authProtectedRoutes = [

    //cabinet
    { path: "/tasks", component: MyFiles },
    { path: "/terms", component: Terms },
    { path: "/meetings", component: Links },
    { path: "/wallet", component: Wallet },
    { path: "/profile", component: Profile },
    { path: "/jurface", component: Jurface },
    { path: "/feedback", component: Feedback },
    { path: "/faq", component: FaqsPage },
    { path: "/contacts", component: UserGrid },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: "/", exact: true, component: () => <Redirect to="/tasks" /> },
];

const publicRoutes = [
    //login
    { path: "/login", component: Login },

    // Utility
    { path: "/pages-maintenance", component: MaintenancePage },
    { path: "/pages-comingsoon", component: ComingSoonPage },
    { path: "/pages-404", component: Error404 },
    { path: "/pages-500", component: Error500 },

    // Auth
    { path: "/auth-login", component: AuthLogin },
    { path: "/auth-register", component: Register },
    { path: "/auth-recoverpw", component: RecoverPassword },
    { path: "/auth-lock-screen", component: LockScreen },
    { path: "/auth-logout", component: Logout },
    { path: "/auth-confirm-mail", component: ConfirmMail },
    { path: "/auth-email-verification", component: EmailVerification },
    { path: "/auth-two-step-verification", component: TwoStepVerification },
];

export { authProtectedRoutes, publicRoutes };