import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Container, DropdownItem, DropdownMenu, Modal, DropdownToggle, Nav, Form, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown, CardBody, CardHeader } from "reactstrap";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import Dropzone from "react-dropzone";

import classnames from "classnames";
import MetaTags from "react-meta-tags";
import Moment from 'react-moment';

import { Saturation, Hue, useColor } from "react-color-palette";
import "react-color-palette/css";

//SimpleBar
import SimpleBar from "simplebar-react";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import chroma from 'chroma-js';

// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";

import Img1 from "../../assets/images/post-1.jpg";
import Img2 from "../../assets/images/post-2.jpg";

import bgimg from "../../assets/images/pattern-bg.jpg";
import { Link } from 'react-router-dom';

import { appContext } from "../../App";

import {useFileUploader} from '../../Hooks/FileUploaderHook'
import { useProfile, useUsers } from "../../Hooks/UserHooks";
import {usePresets} from "../../Hooks/PresetsHooks"

import { TranslateContext } from "../../providers/Translate";
import { NotifyContext } from "../../providers/Notify";

import { useSpring, animated } from '@react-spring/web'

var delfile = null
var dontsave = true
let tagsList = []
let edittagId = ""
let tagEditorValue = ""
const Terms = () => {
  const { userId } = useProfile();
  const dispatch = useDispatch();
  const { language } = React.useContext(TranslateContext)
  const { error: presetserror, LoadPresets } = usePresets()
  const { setNotify } = React.useContext(NotifyContext);

  const tagInput = React.createRef()
  const [activeTag, setActiveTag] = React.useState(null)
  const [showPalette, setShowPalette] = useState(false);
  const [selectedColor, setColor] = useColor("#561ecb");
  const [terms, SetTerms] = React.useState(null)
  const [term, SetNewTerm] = React.useState("")
  const [showterms, SetShowTerms] = React.useState(true)
  const [edittag, SetEditTag] = React.useState(null)

  const [tags, setUsersTags] = React.useState([])

  const {user, LoadTerms, SaveTerms, UserTags, AddTag, RemoveTag, UpdateTag, AssignTagToOrder, RemoveTagFromOrder} = useUsers({onDone: (operation, data) => {
    if (operation == "loadTerms")
    {
        dontsave = true
        var terms = data.trim().split(',')
        var filtered = terms.filter(function (el) {
            return el != null && el != "" && el != undefined;
          });

        SetTerms(filtered)
    }

    if (operation == 'tags') {
        setUsersTags(data)
        tagsList = data
    }

    if (operation == 'updatetag')
    {
        UserTags({variables: {withGlobal: true}})
    }

    if (operation == 'removetag')
    {
        UserTags({variables: {withGlobal: true}})
    }
  }})

  const SaveTermsToServer = () => {

    var filtered = terms.filter(function (el) {
        return el != null && el != "" && el != undefined;
    });

    SaveTerms({variables: {id: userId, terms: filtered.join(',')}})
  }

  useEffect(()=>{
    LoadTerms({variables:{id: userId}})
    UserTags({variables: {withGlobal: true}})
  }, [userId])

  useEffect(()=>{
    if (terms == null || dontsave)
    {
        dontsave = false
        return
    }
    SaveTermsToServer()
  }, [terms])

  const styles = useSpring({
    from: {
      opacity: 0,
      y: '10%',
    },
    to: {
      opacity: 1,
      y: '0%',
    },
    config: { duration: 500 },
  })

  const SaveEventManagement = (e) => {
        
    let newtags = [...tagsList]
    let tagIndex = newtags?.findIndex((t) => t.id === edittagId)
    
    if (tagIndex>=0)
    {
        
        newtags[tagIndex] = {
            ...newtags[tagIndex], name: tagEditorValue
        }
         
        setUsersTags([...newtags])

        UpdateTag({
            variables: {
                id: edittagId,
                name: tagEditorValue
        }})

        edittagId = ""
        tagEditorValue = ""
        SetEditTag(null)
    }
}
                     

useEffect(()=>{
      SetShowTerms(false)

      const TagsKeyPressed = (e) => {
        if (e.key === 'Escape') {
            setActiveTag(null)
            setShowPalette(false)
            SetEditTag(null)
        }
      }

      document.addEventListener('keyup', TagsKeyPressed)
      document.addEventListener('outlineclick', SaveEventManagement)

      return () => {
          document.removeEventListener('keyup', TagsKeyPressed)
          document.removeEventListener('outlineclick', SaveEventManagement)
      }
  }, [])

  useEffect(()=>{
      if (tagInput)
      {
        tagInput?.current?.focus()
        tagEditorValue = tagInput?.current?.value
      }
  }, [tagInput])

  useEffect(()=>{
    edittagId = edittag?.id ?? ""
  }, [edittag])

  useEffect(()=>{
    if (dispatch)
    {
        const breadCrumbItems = {
            title: showterms ? "Мои термины" : "Мои теги",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }
  }, [dispatch, showterms])
    
    return (
        <React.Fragment>
        <MetaTags>
            <title>Кабинет | Таймлист - ИИ расшифровки аудио и автопротоколирования</title>
        </MetaTags>
        <div className="page-content">
            <Container fluid>

                <Row style={{marginBottom: "20px"}}>
                    <Col md={12} lg={12} xl={12}>
                        <button type="button" style={{marginRight: "10px"}} className={!showterms ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => SetShowTerms(false)}>{"Теги"}</button>
                        {/* <button type="button" className={showterms ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => SetShowTerms(true)}>{"Термины"}</button> */}
                    </Col>
                </Row> 

                {showterms &&
                <Row>
                    <Col md={12} lg={12} xl={12}>
                    <animated.div  style={{...styles}}>
                        <Card className="mb-0">
                                        <CardBody>
                                            <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                                <div className="w-100">
                                                    <div className="d-flex flex-column h-100">

                                                        <div className="auth-content my-auto">

                                                            <p><b>Введите в поле ниже специфические слова и выражения, на которые искусственному интеллекту стоит обратить внимание.</b></p>

                                                            <div>
                                                                <div className='alert alert-primary' style={{display: "inline-block"}}>важно: каждый новый термин увеличивает время расшифровки</div>
                                                            </div>

                                                            <form className="mt-4 pt-2" action="/index">
                                                            
                                                            <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                    <Row>
                                                                        <Col md={4} style={{marginBottom:20}}>
                                                                            <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["New term"]} defaultValue="" value={term} onChange={e => SetNewTerm(e.target.value)} />

                                                                            <div className="my-3 text-left">
                                                                                <button className="btn btn-primary waves-effect waves-light" type="button" onClick={()=>{
                                                                                    SetTerms([...terms, term])
                                                                                    SetNewTerm("")
                                                                                }}>{language["Save"]}</button>
                                                                            </div>
                                                                        </Col>

                                                                        <Col  md={8}>
                                                                            {terms?.map((t,i)=>{
                                                                                return <span className='badge text-black badge-pill pl-2' style={{fontSize:18, marginRight:20, marginBottom:20, background:"#F6F7F8"}}>{t}{" "}
                                                                                        <button type="button" className='btn pl-1' onClick={()=>{
                                                                                            terms.splice(i,1)
                                                                                            SetTerms([...terms])
                                                                                        }}>X</button>
                                                                                </span>
                                                                            })}
                                                                        </Col>
                                                                    </Row>
                                                            </div>

                                                                
                                                                
                                                            </form>

                                                          
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                        </Card>
                        </animated.div>
    
                    </Col>
                </Row> }


                {!showterms &&
                <Row>
                    <Col md={12} lg={12} xl={12}>
                    <animated.div  style={{...styles}}>
                        <Card className="mb-0">
                                        <CardBody>
                                            <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                                <div className="w-100">
                                                    <div className="d-flex flex-column h-100">

                                                        <div className="auth-content my-auto">

                                                            <p><b>Управление тегами.</b></p>
                                                         
                                                       </div>

                                                       <div className="mb-4">
                                                                <Row>
                                                                        <Col  md={12} className="mainContainer">
                                                                            {tags?.map((t,i)=>{
                                                                                const color = chroma(activeTag?.id === t.id ? selectedColor.hex : t.color);

                                                                                return <span className='badge text-black badge-pill pl-2' style={{fontSize:18, marginRight:20, marginBottom:20, background:  color.alpha(0.6).css()}}>
                                                                                        <span className='d-flex align-items-center'>
                                                                                        
                                                                                        {edittag?.id == t.id ? <input type={"text"} className="form-control m-0" style={{width:150}} id="tageditor" ref={tagInput} defaultValue={t.name} onChange={e => tagEditorValue = e.target.value } onKeyUp={e => {
                                                                                            if (e.key == "Enter") {
                                                                                                SaveEventManagement(e)
                                                                                            }
                                                                                        }} /> : <span style={{cursor:"text"}} onDoubleClick={()=>{SetEditTag(t)}}>{t.name}{" "}</span> }

                                                                                        <button type="button" className='btn pl-1' style={{cursor:"pointer"}} onClick={()=>{
                                                                                            RemoveTag({
                                                                                                variables: {
                                                                                                    id: t.id
                                                                                                }
                                                                                            })
                                                                                        }}>X</button>
                                                                                        <span style={{width:25, height:25, borderRadius:10, background: color.alpha(1.0).css(), display:"inline-block", cursor:"pointer"}} onClick={()=>{
                                                                                            setActiveTag(t)
                                                                                            setShowPalette(true)
                                                                                        }}></span>
                                                                                        </span>
                                                                                </span>
                                                                            })}
                                                                        </Col>

                                                                        {showPalette && <div style={{width:400, height:200}}>
                                                                            <div style={{position:"absolute", top:100, left:100, width:400, display:"block", padding:20, backgroundColor:"white", borderRadius:15, boxShadow:"0 0 10px 0 rgba(0, 0, 0, 0.2)"}}>
                                                                            <Saturation height={300} color={selectedColor} onChange={setColor} />
                                                                            <Hue color={selectedColor} onChange={setColor} />

                                                                                <button type="button" className='btn pl-1' style={{cursor:"pointer"}} onClick={()=>{
                                                                                    let newtags = [...tags]
                                                                                    let tagIndex = newtags?.findIndex((t) => t.id === activeTag.id)
                                                                                    if (tagIndex>=0)
                                                                                    {

                                                                                        newtags[tagIndex] = {
                                                                                            ...newtags[tagIndex], color: selectedColor?.hex
                                                                                        }
                                                                                         
                                                                                        setUsersTags([...newtags])

                                                                                        UpdateTag({
                                                                                            variables: {
                                                                                                id: activeTag.id,
                                                                                                color: selectedColor?.hex
                                                                                        }})
                                                                                    }
                                                                                    setActiveTag(null)
                                                                                    setShowPalette(false)
                                                                                }}>Установить</button>
                                                                            </div>
                                                                        </div> }  
                                                                </Row>
                                                            </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                        </Card>
                        </animated.div>
    
                    </Col>
                </Row>}
            </Container>
        </div>
    </React.Fragment>
    );
};


export default Terms;

