import React from "react";
import {Button, Card, CardBody} from "reactstrap";
import {Speaker} from "./Speaker";
import { TranslateContext } from '../../../providers/Translate';
import { SuccessIcon } from "../../../assets/images/icons";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { AnaliticsPanel } from "./AnaliticsPanel";

import config from "../../../config";


export const SpeakersPanel = ( { speakers, newSpaekerAwait, saveNewSpeakerName, getProtocol, changeLeadSpeaker, addNewSpeaker, protocolStatus, onDeleteSpeaker, editSpeakerName, onStartEditing, getAnalitic, analiticStatus, onUpdatedTextChange, checkboxes, CheckedVisible } ) => {
  const { language } = React.useContext(TranslateContext)
  const protocolTitleColor = {
    PENDING: 'text-primary',
    SUCCESS: 'text-success',
    FAILURE: 'text-warning',
  }

  if (!speakers.length) return <></>

  return (
    <>
      <Card className="mb-2">
        <CardBody className="p-0">
          <div className="p-3 d-flex justify-content-between align-items-center">
            <b className='m-0 ml-2'>{language["Speakers"]} ({speakers.length})</b>
            <button className='btn btn-outline-secondary' onClick={addNewSpeaker}><i className="fas fa-plus"/></button>
          </div>
        </CardBody>
      </Card>

      {speakers.map((speaker, idx) => (
        <Speaker
          onStartEditing = {onStartEditing}
          editSpeakerName = {editSpeakerName}
          key={idx}
          speaker={speaker}
          saveNewSpeakerName={saveNewSpeakerName}
          changeLeadSpeaker={changeLeadSpeaker}
          onDeleteSpeaker={onDeleteSpeaker}
        />
      ))}

      {/*

Необязательный параметр. Строка. Указать тип протокола, который требуется получить. Варианты - meeting, document, sales, interview, lecture. Если параметр не указан (None) присваивается значение 'meeting'. По умолчанию None.
Пример запроса

      */}

      {config.ui.protocolButton == 1 ? 
      <>

      {document.location.host !== "i.timelist.ru" ? 
       <div className={'d-flex align-items-center mt-4 mb-4 justify-content-center'}>
        <UncontrolledDropdown className="dropdown float-start">
              <DropdownToggle tag="a" className="text-muted dropdown-toggle primary">
                  <button style={{width: 240}} className='btn btn-primary'>Получить резюме</button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" style={{width: 240}}>
                <DropdownItem onClick={ () => getProtocol('meeting', checkboxes) }>{"Универсальное резюме"}</DropdownItem>
                <DropdownItem onClick={ () => getProtocol('meeting', checkboxes) }>{"Резюме 'совещания'"}</DropdownItem>
                <DropdownItem onClick={ () => getProtocol('sales', checkboxes) }>{"Резюме 'продажи'"}</DropdownItem>
                <DropdownItem onClick={ () => getProtocol('interview', checkboxes) }>{"Резюме 'интервью'"}</DropdownItem>
                <DropdownItem onClick={ () => getProtocol('lecture', checkboxes) }>{"Резюме 'обучения'"}</DropdownItem>
                <DropdownItem onClick={ () => getProtocol('document', checkboxes) }>{"Резюме 'документа'"}</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={ () => onUpdatedTextChange('detox') }>{"Обработка 'детокс'"}</DropdownItem>
                <DropdownItem onClick={ () => onUpdatedTextChange('normalized') }>{"Обработка 'деловой стиль'"}</DropdownItem>
                <DropdownItem onClick={ () => onUpdatedTextChange('simplifed') }>{"Обработка 'упрощенный стиль'"}</DropdownItem>
                <DropdownItem divider />
                {document.location.hostname != "gpbcom.timelist.ru" && <DropdownItem onClick={ () => getAnalitic() }>{"Речевая аналитика"}</DropdownItem>}
              </DropdownMenu>
            </UncontrolledDropdown>
      </div> : 
      
      <div className='d-flex align-items-center justify-content-center mt-4 mb-3 w-100'>
        <button style={{width: 250}} className='btn btn-primary' onClick={ () => getProtocol('meeting') }>Получить резюме</button>
      </div>
      }
      
      {/*
        <div className='text-muted font-size-12 mb-4'>
          <p>Для улучшения качества Резюме: </p>
          <ul className="mt-2 mb-2">
            <li>проверьте расшифровку,</li>
            <li>переименуйте спикеров,</li>
            <li>отметьте тех спикеров, чьи слова наиболее важны (председатель комиссии, руководитель или тот, кто
              резюмирует принятые решения).
            </li>
            {document.location.host == "debug.timelist.ru"  && <li style={{fontWeight:"bold"}}>Для более точного резюме Вы можете выбрать только нужные абзацы из расшифровки</li>}
          </ul>
          <b>При необходимости запросите резюме еще раз, если качество результата Вас не устроит.</b>
          </div> 
      
      */}
      </>
      : null}

      { protocolStatus &&
        <div className='mb-2'>
          <div
            className={ `d-flex justify-content-center ${ protocolTitleColor[protocolStatus] }` }>
            { language[protocolStatus] }
          </div>
          <div className='d-flex justify-content-center pt-3'>
            { protocolStatus === 'PENDING' && <div className="spinner-border text-primary" role="status"/> }
            { protocolStatus === 'SUCCESS' && <SuccessIcon/> }
          </div>
        </div>
      }

  {/* аналитику даем только организациям, физики в пролете */}
  {location.host != "i.timelist.ru" && location.host != "gpbcom.timelist.ru"  &&  (
          <AnaliticsPanel
                getAnalitic={getAnalitic}
                analiticStatus={analiticStatus}
          />
      )}

    

    </>
  )
}
